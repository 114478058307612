import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import "./shufflingCards.css";

const ShufflingCards = ({ backgroundImage, isVisible }) => {
  const [cards, setCards] = useState([
    {
      id: 1,
      position: "top",
      content: (
        <div className="lifeCard">
          <h2 className="lifeCardtitle">Life at Atlas Essentials</h2>
          <p className="lifeCardContent">
            At Atlas Essentials, we cultivate a collaborative, innovative, and
            growth-oriented workplace.
          </p>
          <ul>
            <li className="lifeCardPoints">
              <strong>Dynamic and Inclusive Environment</strong>:Encouraging
              ideas and teamwork across all departments.
            </li>
            <li>
              <strong>Supportive Culture</strong>:Empowering employees to thrive
              while contributing to impactful projects in clinical operations,
              finance, quality assurance, data management, HR, and more.
            </li>
            <li>
              <strong>Work-Life Balance</strong>:Promoting well-being alongside
              professional development opportunities.
            </li>
            <li>
              <strong>Dynamic and Inclusive Environment</strong>:Fostering a
              community that values teamwork, excellence, and meaningful
              contributions to healthcare.
            </li>
            <li>
              <strong>Shared Purpose</strong>:Encouraging ideas and teamwork
              across all departments.{" "}
            </li>
          </ul>
          <p className="lifeCardContent">
            Join Atlas Essentials and be part of a team making a real difference
            in the healthcare industry.{" "}
          </p>
        </div>
      ),
    },
    {
      id: 2,
      position: "middle",
      content: (
        <div className="lifeCard">
          <h2 className="lifeCardtitle">Our Culture at Atlas Essentials</h2>
          <p className="lifeCardContent">
            At Atlas Essentials, we cultivate a vibrant and inclusive culture
            where every individual feels valued and empowered. Our friendly and
            collaborative environment fosters open communication, enabling
            seamless teamwork on innovative projects. We celebrate diversity
            through cultural activities that strengthen connections and
            highlight our unique backgrounds. With a strong commitment to
            personal and professional growth, we provide our employees with the
            support and resources they need to excel. Together, we drive success
            through teamwork and a shared vision, embodying our belief in Making
            Progress Possible…
          </p>
        </div>
      ),
    },
    {
      id: 3,
      position: "bottom",
      content: (
        <div className="lifeCard">
          <h2 className="lifeCardtitle">
            Diversity and Inclusion at Atlas Essentials
          </h2>
          <p className="lifeCardContent">
            At Atlas Essentials, diversity and inclusion are at the core of our
            success. We embrace unique perspectives and promote equity, ensuring
            every individual feels valued and empowered. By fostering an
            inclusive culture, we drive innovation, collaboration, and
            creativity, celebrating diversity as a strength that fuels our
            growth and progress.
          </p>
        </div>
      ),
    },
  ]);

  const [isShuffling, setIsShuffling] = useState(false);
  const [isHovered, setIsHovered] = useState(false); // Local hover state

  // Shuffle the cards when section becomes visible and not hovered
  useEffect(() => {
    if (!isVisible || isHovered) return; // Skip shuffling if section is not in view or if hovered

    const shuffleCards = () => {
      setIsShuffling(true);

      setTimeout(() => {
        setCards((prevCards) => {
          const updatedCards = [...prevCards];
          const [topCard] = updatedCards.splice(0, 1);
          updatedCards.push(topCard); // Move the top card to the bottom

          // Reassign positions for the shuffled cards
          return updatedCards.map((card, index) => {
            const newPosition =
              index === 0 ? "top" : index === 1 ? "middle" : "bottom";
            return { ...card, position: newPosition };
          });
        });

        setIsShuffling(false);
      }, 2500); // Wait for 2500ms before resetting shuffling state
    };

    const shuffleInterval = setInterval(shuffleCards, 3000);

    return () => {
      clearInterval(shuffleInterval);
    };
  }, [isVisible, isHovered]); // Only trigger shuffle when isVisible is true and isHovered is false

  return (
    <div className="lifeCardHolder">
      <div className="lifeCardContainer">
        {cards.map((card) => (
          <Card
            key={card.id}
            className={`cardPos ${card.position} ${
              isShuffling && card.position === "top" ? "go-time" : ""
            }`}
            onMouseEnter={() => setIsHovered(true)} // Set hover state to true on card hover
            onMouseLeave={() => setIsHovered(false)} // Reset hover state to false when mouse leaves the card
          >
            <Card.Body>
              {card.content}
              <div className="hover-message">Hover to pause</div>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ShufflingCards;
