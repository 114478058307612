import React from "react";
import { Carousel, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS import
import AtlasBrief from "../ATLAS_BRIEF";

import ValueCard from "../../../../COMPONENTS/COMMON/CARD/VALUE_CARDS";
import "./index.css";

const HeroCarousel = () => {
  return (
    // <Container>
    <Carousel
      controls={false}
      indicators={false}
      interval={5000}
      pause="hover"
      className="heroCarousel"
    >
      <Carousel.Item>
        <ValueCard />
      </Carousel.Item>
      <Carousel.Item>
        <AtlasBrief />
      </Carousel.Item>
    </Carousel>
    // </Container>
  );
};

export default HeroCarousel;
