import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import atlasLogo from "./../../../../ASSETS/AtlasLogo.png";
import HeroCarousel from "../HERO_CAROUSAL";

const HeroSection = () => {
  return (
    <div fluid className="hero-section" id="home">
      <div className="heroContent">
        <video
          src="https://videos.pexels.com/video-files/853789/853789-sd_640_360_25fps.mp4"
          alt="Hero Banner"
          className="hero-image"
          autoPlay
          loop
          muted
        />
        <div className="hero-overlay"></div>
        <Container>
          <Row>
            <Col xs={12} md={4} className="hero-text-container">
              <div className="hero-text">
                <img
                  src={atlasLogo}
                  alt="Atlas Logo"
                  className="hero-atlas-brand-logo"
                />
                <h1 className="heroTitle">ATLAS ESSENTIALS INDIA</h1>{" "}
                <p className="subscript">A KUR International Company</p>
                <h2 className="heroSubTitle">Making progress possible…</h2>
              </div>
            </Col>
            <Col xs={12} md={8} className="hero-carousal-container">
              <HeroCarousel />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default HeroSection;
