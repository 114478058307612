import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./whatWeDo.css";
import vexLogo from "./../../../../ASSETS/Vex_logo.png";
import kurLogo from "./../../../../ASSETS/KurrLogo_prev_ui.png";

const whatWeDoSecttion = () => {
  return (
    <div className="whatWeDo-section" id="what-we-do">
      {/* <h3 className="servicesSectionTitle">Clinical Services</h3> */}
      <Container>
        <Row className="column-content-row">
          <div className="whatWeDoTitle"> WHAT WE DO</div>

          {/* Left Column for Vexillum Biospecimen Solutions */}
          <Col xs={12} lg={6} className="servicesColumn">
            <div className="column-content-inner">
              <img
                src={vexLogo}
                className="vex-brand-logo"
                alt="Vexillum Logo"
              />
              <h3>Vexillum Biospecimen Solutions</h3>
              <p>
                Vexillum Biospecimen Solutions is a leading provider of
                high-quality biospecimen whatWeDo, specializing in prospective
                collections and biobanking across a wide range of therapeutic
                areas. Our expertise allows us to deliver tailored collections
                that meet the specific needs of diagnostic, pharmaceutical,
                biotech, and MedTech industries. From infectious diseases to
                oncology to respiratory conditions, and beyond, our
                geographically diverse network of collection sites ensures
                access to varied patient populations. With a strong commitment
                to compliance and scientific rigor, all specimens are collected
                under IRB-approved protocols, providing reliable and ethically
                sourced samples. At Vexillum, we are dedicated to empowering
                research with reliable biospecimens that drive impactful
                research and innovation.
              </p>
            </div>
          </Col>
          {/* Right Column for KUR Research */}
          <Col xs={12} lg={6} className="servicesColumn">
            <div className="column-content-inner">
              <img src={kurLogo} className="kur-brand-logo" alt="KUR Logo" />
              <h3>
                KUR Research (KUR Diagnostics and Urgent Care Clinical Trials)
              </h3>
              <p>
                KUR Research, a Site Management Organization (SMO), plays a
                pivotal role in supporting clinical research and clinical trials
                across various therapeutic areas. Our SMO is a unique
                combination of diagnostic centers, urgent care facilities, and
                specialized private practices, providing a diverse and robust
                platform for research. We provide a wide range of whatWeDo that
                cover therapeutics, diagnostics, preventative, and exploratory
                research projects, ensuring a broad scope of capabilities to
                meet diverse project needs. With an extensive network of
                experienced clinical sites, we ensure seamless coordination of
                trials, from patient recruitment to data collection. Our team of
                skilled professionals is dedicated to maintaining the highest
                standards of compliance, quality, and efficiency throughout the
                research process.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default whatWeDoSecttion;
