// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brief_briefSection__VFz1z {
  margin: 0;
  padding: 5em;
  width: 100%;
  height: 100%;
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  color: aliceblue;
  background: transparent;
  z-index: 200;
  font-size: 1.1em;
}

@media (max-width: 767px) {
  .brief_title__GOvUL {
    font-size: 2.5em;
  }

  .brief_subtitle__8A3md {
    font-size: 1.5em;
  }

  .brief_briefSection__VFz1z {
    /* height: 100%; */
    font-size: 1.25em;
  }

  .brief_description__5-fs8 {
    font-size: 1rem;
    padding: 0 1em;
  }

  .brief_cardWrapper__Vl1Jg {
    padding: 0.5em;
  }
}

@media (max-width: 576px) {
  .brief_title__GOvUL {
    font-size: 2.2em;
  }

  .brief_subtitle__8A3md {
    font-size: 1.3em;
  }

  .brief_briefSection__VFz1z {
    padding: 1.5em 1em;
  }

  .brief_description__5-fs8 {
    font-size: 0.95rem;
    padding: 0 1em;
  }
}

@media (max-width: 400px) {
  .brief_title__GOvUL {
    font-size: 1.8em;
  }

  .brief_subtitle__8A3md {
    font-size: 1.2em;
  }

  .brief_briefSection__VFz1z {
    padding: 1em;
  }

  .brief_description__5-fs8 {
    font-size: 0.9rem;
    padding: 0 1em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/HOME/SECTIONS/ATLAS_BRIEF/brief.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,4CAA4C;EAC5C,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,cAAc;EAChB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB;AACF","sourcesContent":[".briefSection {\n  margin: 0;\n  padding: 5em;\n  width: 100%;\n  height: 100%;\n  font-family: \"Times New Roman\", Times, serif;\n  font-style: italic;\n  color: aliceblue;\n  background: transparent;\n  z-index: 200;\n  font-size: 1.1em;\n}\n\n@media (max-width: 767px) {\n  .title {\n    font-size: 2.5em;\n  }\n\n  .subtitle {\n    font-size: 1.5em;\n  }\n\n  .briefSection {\n    /* height: 100%; */\n    font-size: 1.25em;\n  }\n\n  .description {\n    font-size: 1rem;\n    padding: 0 1em;\n  }\n\n  .cardWrapper {\n    padding: 0.5em;\n  }\n}\n\n@media (max-width: 576px) {\n  .title {\n    font-size: 2.2em;\n  }\n\n  .subtitle {\n    font-size: 1.3em;\n  }\n\n  .briefSection {\n    padding: 1.5em 1em;\n  }\n\n  .description {\n    font-size: 0.95rem;\n    padding: 0 1em;\n  }\n}\n\n@media (max-width: 400px) {\n  .title {\n    font-size: 1.8em;\n  }\n\n  .subtitle {\n    font-size: 1.2em;\n  }\n\n  .briefSection {\n    padding: 1em;\n  }\n\n  .description {\n    font-size: 0.9rem;\n    padding: 0 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"briefSection": `brief_briefSection__VFz1z`,
	"title": `brief_title__GOvUL`,
	"subtitle": `brief_subtitle__8A3md`,
	"description": `brief_description__5-fs8`,
	"cardWrapper": `brief_cardWrapper__Vl1Jg`
};
export default ___CSS_LOADER_EXPORT___;
