import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-scroll";
import "./index.css";
import atlasLogo from "./../../ASSETS/AtlasLogo.png";

const NavBar = () => {
  return (
    <Navbar fixed="top" className="navbar" expand="lg">
      <Container
        fluid
        className="d-flex justify-content-between align-items-center"
      >
        <Navbar.Brand>
          <a href="/" className="atlas-brand-logo">
            <img
              src={atlasLogo}
              alt="Atlas Logo"
              className="atlas-brand-logo"
            />
            ATLAS ESSENTIALS INDIA
          </a>
          <p className="navbarSubscript">A KUR International Company</p>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" className="ms-auto" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              as={Link}
              to="home"
              smooth={true}
              duration={500}
              offset={-70}
              activeClass="active"
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="about-us"
              smooth={true}
              duration={500}
              offset={-70}
              activeClass="active"
            >
              About Us
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="what-we-do"
              smooth={true}
              duration={500}
              offset={-100}
              activeClass="active"
            >
              What We Do
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="services"
              smooth={true}
              duration={500}
              offset={-100}
              activeClass="active"
            >
              Services
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="life-at-atlas"
              smooth={true}
              duration={500}
              activeClass="active"
            >
              Life At Atlas
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="careers"
              smooth={true}
              duration={500}
              offset={-70}
              activeClass="active"
            >
              Careers
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="contact"
              smooth={true}
              duration={500}
              offset={-100}
              activeClass="active"
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
