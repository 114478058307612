import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css"; // Assuming the CSS above is in this file
import Aos from "aos";
import "aos/dist/aos.css";

const VisionServicesSection = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      easing: "ease-in-out",
      startEvent: "load", // Ensures AOS starts after the page is loaded
    });
  }, []);

  return (
    <div className="vison-servicesSection" id="about-us">
      <Container>
        <Row>
          <Col xs={12} sm={6}>
            <div className="rectangle1" data-aos="fade-left" data-aos-delay="0">
              <h3>Vision</h3>
              <p>
                To be the cornerstone of global research and innovation by
                providing exceptional administrative support and integrated
                infrastructure that empowers KUR International to excel in
                clinical research, diagnostics, and patient care. With a
                commitment to operational excellence and innovation, we enable
                scientific breakthroughs, improve patient outcomes, and shape
                the future of healthcare—guided by our motto, "Making Progress
                Possible."
              </p>
            </div>
          </Col>

          <Col xs={12} sm={6}>
            <div
              className="rectangle2"
              data-aos="fade-right"
              data-aos-delay="0"
            >
              <h3>Our Business</h3>
              <p>
                KUR International is a global healthcare conglomerate dedicated
                to advancing science, research, and patient well-being. It
                focuses on delivering an advanced and seamless clinical
                experience for researchers, aiming to achieve superior
                healthcare outcomes. KUR operates in various sectors of
                healthcare, providing innovative solutions and services that
                enhance the quality of medical research and support clinical
                studies. KUR’s commitment to improving research experiences and
                outcomes is central to its mission.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VisionServicesSection;
