// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vertical-center {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10em;
}

#notfound {
  text-align: center;
}

#notfound h1 {
  font-size: 150px;
  margin: 0;
  font-weight: 600;
}

#notfound h2 {
  font-size: 36px;
  margin: 20px 0;
  font-weight: 600;
}

#notfound p {
  font-size: 18px;
  margin-bottom: 20px;
}

#notfound a {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #007bff;
  color: #aaaaaa;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}

#notfound a:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/404/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".vertical-center {\n  min-height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 10em;\n}\n\n#notfound {\n  text-align: center;\n}\n\n#notfound h1 {\n  font-size: 150px;\n  margin: 0;\n  font-weight: 600;\n}\n\n#notfound h2 {\n  font-size: 36px;\n  margin: 20px 0;\n  font-weight: 600;\n}\n\n#notfound p {\n  font-size: 18px;\n  margin-bottom: 20px;\n}\n\n#notfound a {\n  display: inline-block;\n  padding: 10px 20px;\n  margin-top: 20px;\n  background-color: #007bff;\n  color: #aaaaaa;\n  border-radius: 4px;\n  text-decoration: none;\n  transition: background-color 0.3s;\n}\n\n#notfound a:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
