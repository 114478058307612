import React from "react";
import "./index.css";
import HeroSection from "./SECTIONS/HERO";
import ServicesSection from "./SECTIONS/SERVICES";
import VisionServicesSection from "./SECTIONS/VISION_BUSINESS";
import WhatWeDo from "./SECTIONS/WHAT_WE_DO";
import LifeAtAtlasSection from "./SECTIONS/LIFE_AT_ATLAS";
import Careers from "./SECTIONS/CAREERS";
import Contact from "./SECTIONS/CONTACT";

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <VisionServicesSection />
      <WhatWeDo />
      <ServicesSection />
      <LifeAtAtlasSection />
      <Careers />
      <Contact />
    </>
  );
};

export default HomePage;
