import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./services.module.css";

const WhatWeDo = () => {
  const [activeSection, setActiveSection] = useState(0);

  const handleClick = (index) => {
    setActiveSection(index);
  };

  const sections = [
    {
      title: "Finance",
      content: (
        <>
          <p className={styles.sectionStartContent}>
            At Atlas India, our Finance department stands at the forefront of
            managing and optimizing financial operations across both the United
            States and India. The finance team provides:
          </p>
          <ul className={styles.sectionPoints}>
            <li>Integrated support to KUR's business units</li>
            <li>
              Aligns financial strategies with business objectives, contributing
              to cost management.
            </li>
            <li>
              Continuously enhances capabilities to deliver value and support to
              KUR International's business strategy.
            </li>
            <li>
              We support comprehensive financial operations such as accounting,
              asset and cash flow management, compliance, reporting, payroll,
              budgeting, and cost analysis.
            </li>
          </ul>
        </>
      ),
      img: "https://media.istockphoto.com/id/1133887502/photo/paperwork-and-hands-on-a-board-room-table-at-a-business-presentation-or-seminar.webp?a=1&b=1&s=612x612&w=0&k=20&c=F6Zc8q--CNAvWoAN9Aow_hjSQoHwo47De2wp5CkEPhs=",
    },
    {
      title: "Quality Assurance",
      content: (
        <>
          <p className={styles.sectionStartContent}>
            Our QA team ensures excellence in clinical studies through:
          </p>
          <ul className={styles.sectionPoints}>
            <li>
              Maintaining the highest standards of data accuracy and
              reliability.
            </li>
            <li>
              Conducting comprehensive audits to ensure compliance and improve
              outcomes.
            </li>
            <li>
              Developing advanced electronic source systems for efficient data
              management.
            </li>
            <li>
              Accurately administering the Clinical Trial Management System.
            </li>
            <li>
              Equipping site staff with best practices in data entry and
              regulatory compliance through training and guidance.
            </li>
          </ul>
        </>
      ),
      img: "https://media.istockphoto.com/id/2150522257/photo/businessman-holding-and-showing-the-best-quality-assurance-with-five-stars-iso-certification.webp?a=1&b=1&s=612x612&w=0&k=20&c=OMLSJ6_hQFrZfaA2TsxPe-umBhYDgVU9b8ac5cxMij0=",
    },
    {
      title: "IT Department",
      content: (
        <>
          <p className={styles.sectionStartContent}>
            At Atlas, our IT Department ensures seamless technology operations
            to support clinical research and healthcare services by:
          </p>
          <ul className={styles.sectionPoints}>
            <li>
              Delivering secure and reliable systems to streamline clinical
              operations and enhance collaboration.
            </li>
            <li>
              Managing clinical infrastructure, data security, application
              development, and IT support.
            </li>
            <li>
              Providing real-time issue resolution for uninterrupted global
              research activities.
            </li>
            <li>
              Implementing strict cybersecurity measures in compliance with
              healthcare regulations.
            </li>
            <li>
              Equipping site staff with best practices in data entry and
              regulatory compliance through training and guidance.
            </li>
          </ul>
          <p className={styles.sectionEndContent}>
            Our expert team tailors IT solutions to meet the unique needs of the
            healthcare industry while maintaining the highest standards of
            security and efficiency.
          </p>
        </>
      ),
      img: "https://images.pexels.com/photos/574069/pexels-photo-574069.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
    {
      title: "Human Resources",
      content: (
        <>
          <p className={styles.sectionStartContent}>
            Our HR department is dedicated to fostering a positive workplace and
            supporting employee success. We offer:
          </p>
          <ul className={styles.sectionPoints}>
            <li>
              Recruiting top talent while promoting diversity and inclusion.
            </li>
            <li>Providing smooth onboarding experiences for new hires.</li>
            <li>
              Addressing concerns, managing grievances, and fostering a
              respectful workplace.
            </li>
            <li>Ensuring ethical standards and a safe, compliant workplace.</li>
            <li>
              Encouraging growth through feedback, goal setting, and annual
              reviews.
            </li>
          </ul>
          <p className={styles.sectionEndContent}>
            Our HR team strives to create a thriving, inclusive, and supportive
            work environment.
          </p>
        </>
      ),
      img: "https://images.pexels.com/photos/4269205/pexels-photo-4269205.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
  ];

  return (
    <div id="services" className={styles.servicesSection}>
      <Container>
        <Row>
          <ul className={styles.title}>
            {sections.map((section, index) => (
              <li
                key={index}
                onClick={() => handleClick(index)}
                className={
                  activeSection === index
                    ? `${styles.titleList} ${styles.active}`
                    : styles.titleList
                }
              >
                {section.title}
              </li>
            ))}
          </ul>
        </Row>

        <Row className={styles.content}>
          {sections.map(
            (section, index) =>
              activeSection === index && (
                <Col key={index} xs={12} className="mb-4">
                  <h3 className={styles.sectionTitle}>{section.title}</h3>
                  <Row>
                    <Col xs={12} md={4}>
                      <img
                        src={section.img}
                        alt={section.title}
                        className={styles.sectionImage}
                      />
                    </Col>
                    <Col xs={12} md={8} className={styles.sectionContent}>
                      {section.content}
                    </Col>
                  </Row>
                </Col>
              )
          )}
        </Row>
      </Container>
    </div>
  );
};

export default WhatWeDo;
