import React, { useState, useEffect, useRef, useMemo } from "react";
import ShufflingCards from "../../../../COMPONENTS/COMMON/CARD/SHUFFLING_CARDS";
import { Container } from "react-bootstrap";

const LifeAtAtlasSection = () => {
  const [backgroundImage, setBackgroundImage] = useState(
    "https://thumbs.dreamstime.com/b/business-collage-scene-business-person-work-business-collage-scene-business-person-action-work-131546625.jpg?w=768"
  );
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const sectionRef = useRef(null);

  const backgroundImages = useMemo(
    () => [
      "https://thumbs.dreamstime.com/b/business-collage-scene-business-person-work-business-collage-scene-business-person-action-work-131546625.jpg?w=768",
      "https://thumbs.dreamstime.com/b/background-image-meeting-room-modern-office-interior-design-aigt-spacious-lobby-seating-area-decor-contemporary-324934485.jpg?w=992",
      "https://thumbs.dreamstime.com/b/modern-corporate-office-building-14822468.jpg?w=768",
    ],
    []
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    const currentSectionRef = sectionRef.current;

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible && !isHovered) {
      const backgroundInterval = setInterval(() => {
        setBackgroundImage((prevBackground) => {
          const currentIndex = backgroundImages.indexOf(prevBackground);
          const nextIndex = (currentIndex + 1) % backgroundImages.length;
          return backgroundImages[nextIndex];
        });
      }, 3000);

      return () => {
        clearInterval(backgroundInterval);
      };
    }
  }, [isVisible, isHovered, backgroundImages]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      ref={sectionRef}
      className="life-at-atlas-section"
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover", // Responsive background sizing
        transition: "background-image 1s ease-in-out",
      }}
      id="life-at-atlas"
    >
      <Container>
        <ShufflingCards
          backgroundImage={backgroundImage}
          isVisible={isVisible}
          isHovered={isHovered}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </Container>
    </div>
  );
};

export default LifeAtAtlasSection;
