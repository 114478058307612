// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactForm {
  color: #000;
  margin-bottom: 0.5em;
  background-color: #88aa99;
  border-radius: 0.5em;
  box-shadow: 1em 2em 2em rgba(0, 0, 0, 0.1);
}

.contacttitle {
  text-align: center;
}

.contactForm .form-control,
.contactForm .form-select {
  line-height: 2em;
  width: 100%;
  border: 1px solid rgba(83, 81, 81, 0.5);
  background-color: transparent;

  &:focus {
    outline: none;
    border-color: #aab5b7;
    background-color: transparent !important;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
}

.contactForm .text-danger {
  color: red !important; /* Apply red color to the asterisk */
}

.formSubmit {
  background-color: rgb(79, 81, 79) !important;
  border-color: rgb(79, 81, 79) !important;
}

.formSection {
  display: flex;
  flex-direction: column;
}

.formBtn {
  background-color: rgb(62, 83, 83) !important;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  padding: 15px 36px;
  letter-spacing: 0.25em;
  text-align: center;
  border: none;
  cursor: pointer;
  width: 50%;
  border-radius: 5px;

  &:hover {
    background-color: rgb(62, 83, 83) !important;
    color: #fff;
    font-weight: 1000;
  }
}
`, "",{"version":3,"sources":["webpack://./src/COMPONENTS/FORM/CONTACT/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,oBAAoB;EACpB,yBAAyB;EACzB,oBAAoB;EACpB,0CAA0C;AAC5C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;EAEE,gBAAgB;EAChB,WAAW;EACX,uCAAuC;EACvC,6BAA6B;;EAE7B;IACE,aAAa;IACb,qBAAqB;IACrB,wCAAwC;EAC1C;;EAEA;IACE,+BAA+B;EACjC;AACF;;AAEA;EACE,qBAAqB,EAAE,oCAAoC;AAC7D;;AAEA;EACE,4CAA4C;EAC5C,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,4CAA4C;EAC5C,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,UAAU;EACV,kBAAkB;;EAElB;IACE,4CAA4C;IAC5C,WAAW;IACX,iBAAiB;EACnB;AACF","sourcesContent":[".contactForm {\n  color: #000;\n  margin-bottom: 0.5em;\n  background-color: #88aa99;\n  border-radius: 0.5em;\n  box-shadow: 1em 2em 2em rgba(0, 0, 0, 0.1);\n}\n\n.contacttitle {\n  text-align: center;\n}\n\n.contactForm .form-control,\n.contactForm .form-select {\n  line-height: 2em;\n  width: 100%;\n  border: 1px solid rgba(83, 81, 81, 0.5);\n  background-color: transparent;\n\n  &:focus {\n    outline: none;\n    border-color: #aab5b7;\n    background-color: transparent !important;\n  }\n\n  &::placeholder {\n    color: rgba(255, 255, 255, 0.7);\n  }\n}\n\n.contactForm .text-danger {\n  color: red !important; /* Apply red color to the asterisk */\n}\n\n.formSubmit {\n  background-color: rgb(79, 81, 79) !important;\n  border-color: rgb(79, 81, 79) !important;\n}\n\n.formSection {\n  display: flex;\n  flex-direction: column;\n}\n\n.formBtn {\n  background-color: rgb(62, 83, 83) !important;\n  color: #fff;\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 1.2;\n  padding: 15px 36px;\n  letter-spacing: 0.25em;\n  text-align: center;\n  border: none;\n  cursor: pointer;\n  width: 50%;\n  border-radius: 5px;\n\n  &:hover {\n    background-color: rgb(62, 83, 83) !important;\n    color: #fff;\n    font-weight: 1000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
