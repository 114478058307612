import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Form,
  Container,
  Button,
  Toast,
  Spinner,
  Col,
  Row,
} from "react-bootstrap";
import { api } from "../../../CONSTANTS";
import "./index.css";
import { axiosPost } from "../../../UTILS/axios";

const schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  comments: Yup.string().optional(),
});

// Form fields configuration
const formFields = [
  { name: "name", label: "Name (required)", type: "text" },
  { name: "phone", label: "Phone (required)", type: "tel" },
  { name: "email", label: "Your email (required)", type: "email" },
  { name: "message", label: "Message", type: "textarea" },
];

const ContactForm = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    console.log(41);
    setLoading(true);
    try {
      const response = await axiosPost(api, data);
      console.log({ response });
      setToastMessage("Form submitted successfully!");
      setToastVariant("success");
      reset();
    } catch (error) {
      setToastMessage("Error submitting form. Please try again.");
      setToastVariant("danger");
    } finally {
      setShowToast(true);
      setLoading(false);
    }
  };

  const renderField = ({ name, label, type, options }) => (
    <Form.Group controlId={`form${name}`} key={name}>
      <Form.Label>
        {label} {type !== "textarea" && <span className="text-danger">*</span>}
      </Form.Label>
      {type === "textarea" ? (
        <Form.Control
          as="textarea"
          rows={3}
          {...register(name)}
          isInvalid={!!errors[name]}
        />
      ) : (
        <Form.Control
          type={type}
          {...register(name)}
          isInvalid={!!errors[name]}
        />
      )}
      <Form.Control.Feedback type="invalid">
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );

  return (
    <Container>
      <Row>
        <Col xs={3}></Col>
        <Col xs={12} md={6} className="contactForm p-5">
          <Form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            className="formSection"
          >
            {formFields.map(renderField)}
            <Form.Group>
              <div className="d-flex justify-content-center mt-4 contactButton">
                <Button type="submit" disabled={loading} className="formBtn">
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </Form.Group>
          </Form>
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
            className={`bg-${toastVariant} text-white position-fixed`}
            style={{ bottom: "20px", right: "20px" }}
          >
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;
