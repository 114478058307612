import React from "react";
import { Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ValueCard.module.css";
import {
  faHandshake,
  faScaleBalanced,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

const cardData = [
  {
    id: 1,
    title: "Excellence",
    icon: faStar,
    content:
      "Committing to the highest standards of quality and efficiency in all services, from biospecimen collection to clinical trial management.",
  },
  {
    id: 2,
    title: "Collaboration",
    icon: faHandshake,
    content:
      "Fostering strong partnerships with clients, stakeholders, and global teams to achieve common goals and drive innovation.",
  },
  {
    id: 3,
    title: "Integrity",
    icon: faScaleBalanced,
    content:
      "Upholding the highest ethical standards in all operations, ensuring transparency and trustworthiness in every action.",
  },
];

const ValueCard = () => {
  return (
    <div className={styles.valueCardsContainer}>
      {cardData.map(({ id, title, content, icon }) => (
        <Col xs={12} sm={4} md={3} key={id}>
          <Card className={styles.customValueCard}>
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon icon={icon} className={styles.cardIcon} />
            </div>
            <Card.Body>
              <Card.Title className={styles.valueCardTitle}>{title}</Card.Title>
              <Card.Text className={styles.valueCardText}>{content}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </div>
  );
};

export default ValueCard;
