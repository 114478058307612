// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.column-content-row {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: #333;
  margin-top: 1em !important;
  padding: 1em;
  width: 100%;
}
.whatWeDoTitle {
  font-size: 4em;
  display: flex;
  justify-content: center;
}
.servicesColumn {
  margin-bottom: 1em;
}

.column-content-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 2em;
  background-color: #88aa99;
  border-radius: 2em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.servicesColumnText {
  margin-top: 2em;
}

.kur-brand-logo {
  display: block;
  margin: 0 auto;
  height: 200px;
}
.vex-brand-logo {
  display: block;
  margin: 0 auto;
  height: 225px;
}

.whatWeDo-section {
  /* background-color: #c6d1d3 !important; */
  padding: 2em;
  position: relative;
  background-color: #bec3c5ce;
}

.servicesSectionTitle {
  text-align: center;
  font-size: 3em;
  text-shadow: 1px 1px 1px rgba(136, 136, 170, 1);
  margin-bottom: 0.25em;
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/HOME/SECTIONS/WHAT_WE_DO/whatWeDo.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,WAAW;EACX,0BAA0B;EAC1B,YAAY;EACZ,WAAW;AACb;AACA;EACE,cAAc;EACd,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB;;iDAE+C;AACjD;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,aAAa;AACf;AACA;EACE,cAAc;EACd,cAAc;EACd,aAAa;AACf;;AAEA;EACE,0CAA0C;EAC1C,YAAY;EACZ,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,+CAA+C;EAC/C,qBAAqB;EACrB,yBAAyB;AAC3B","sourcesContent":[".column-content-row {\n  display: flex;\n  justify-content: space-between;\n  padding: 20px;\n  color: #333;\n  margin-top: 1em !important;\n  padding: 1em;\n  width: 100%;\n}\n.whatWeDoTitle {\n  font-size: 4em;\n  display: flex;\n  justify-content: center;\n}\n.servicesColumn {\n  margin-bottom: 1em;\n}\n\n.column-content-inner {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  padding: 2em;\n  background-color: #88aa99;\n  border-radius: 2em;\n  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,\n    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,\n    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;\n}\n\n.servicesColumnText {\n  margin-top: 2em;\n}\n\n.kur-brand-logo {\n  display: block;\n  margin: 0 auto;\n  height: 200px;\n}\n.vex-brand-logo {\n  display: block;\n  margin: 0 auto;\n  height: 225px;\n}\n\n.whatWeDo-section {\n  /* background-color: #c6d1d3 !important; */\n  padding: 2em;\n  position: relative;\n  background-color: #bec3c5ce;\n}\n\n.servicesSectionTitle {\n  text-align: center;\n  font-size: 3em;\n  text-shadow: 1px 1px 1px rgba(136, 136, 170, 1);\n  margin-bottom: 0.25em;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
